import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/style/all.scss'
import { useIcon } from '@/hooks/useIcon.js'
import Icon from '@/components/Icon'

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(useIcon)
  .component('Icon', Icon)
  .mount('#app')

// const $ = require('jquery')
// window.$ = $

// read fb SDK
// ;(function (d, s, id) {
//   var js
//   var fjs = d.getElementsByTagName(s)[0]
//   if (d.getElementById(id)) {
//     return
//   }
//   js = d.createElement(s)
//   js.id = id
//   js.src = 'https://connect.facebook.net/zh_TW/sdk.js'
//   fjs.parentNode.insertBefore(js, fjs)
// })(document, 'script', 'facebook-jssdk')
