<template>
  <footer
    id="footer"
    class="small border-top"
  >
    <div class="container-fluid gx-0">
      <div class="row gx-0">
        <div class="position-relative">
          <div class="position-absolute top-50 start-50 translate-middle">
            <img
              id="nanaicon"
              class="img-fluid d-block py-4 mx-auto"
              src="@/assets/img/img-logo.png"
              alt="logo"
              style="height: 120px"
            >
          </div>
          <div class="d-flex flex-row-reverse">
            <div
              class="p-2"
              style="margin-right: 20px"
            >
              <a
                href="https://www.facebook.com/groups/Natasha1001tw/?ref=web_social_plugin"
                class="d-inline-block position-absolute mb-3"
                target="_blank"
              >
                <Icon
                  name="messenger"
                  :class="$style.icon"
                  style="margin-top: 115px"
                /></a>
            </div>
            <div class="p-2">
              <iframe
                src="https://www.facebook.com/plugins/group.php?href=https%3A%2F%2Fwww.facebook.com%2Fgroups%2FNatasha1001tw&width=280&show_metadata=false&appId=927456674601809&height=241"
                width="280"
                height="241"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
              <!-- <iframe
                src="https://www.facebook.com/plugins/group.php?href=https%3A%2F%2Fwww.facebook.com%2Fgroups%2FNatasha1001tw&width=300&show_metadata=false&appId=305085964883176&height=254"
                width="300"
                height="254"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              /> -->
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="bg-black text-white text-center small py-2">
            COPYRIGHT © 2021. POWERED BY
            <a
              class="text-white text-decoration-none"
              :href="`https://admin.omniec.com.tw/login?clientID=${clientID}`"
              target="_blank"
            >
              REINBACH</a>.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  setup () {
    const clientID = process.env.VUE_APP_clientID

    return { clientID }
  }
}
</script>
<style lang="scss" module scoped>
.icon {
  color: $primary;
  height: 1.5rem;
  width: 1.5rem;
}
@media screen and (max-width: 768px) {
  img {
    opacity: 0;
  }
}
</style>
