<template>
  <div
    :class="[$style.alert, {[$style.show]: alert.show}, {[$style.failed]: !alert.success}]"
    class="d-flex flex-row align-items-center justify-content-center"
  >
    <div class="me-2">
      <Icon
        v-if="alert.success"
        :class="$style.icon"
        name="check"
      />
      <Icon
        v-if="!alert.success"
        :class="$style.icon"
        name="error"
      />
    </div>
    <span class="fs-4 text-white">{{ alert.text || '' }}</span>
    <div
      role="button"
      class="p-2 me-3 position-absolute end-0"
      @click="closeAlert"
    >
      <Icon
        :class="$style.cross"
        name="close"
      />
    </div>
  </div>
</template>
<script>
import { computed, inject } from 'vue'
import { useStore } from 'vuex'
export default {
  setup () {
    const { getters } = useStore()

    const setAlert = inject('setAlert')

    const alert = computed(() => getters.getAlert)
    const closeAlert = () => {
      setAlert(false)

      setTimeout(() => {
        setAlert(false)
      }, 100)
    }

    return { alert, closeAlert }
  }
}
</script>
<style lang="scss" module>

  .alert {
    background-color: $success;
    left: 50%;
    width: 100vw;
    height: 70px;
    position: fixed;
    top: 0;
    transform: translate(-50%, -150%);
    transition: transform 0.5s;
    user-select: none;
    // header z-index 1020
    z-index: 1019;
  }

  .show {
    transform: translate(-50%, 110%);
  }

  .cross {
    fill: $white;
    height: 1.5rem;
    margin-right: 0.5rem;
    width: 1.5rem;
  }

  .icon {
    fill: $white;
    height: 1.6rem;
    margin: 0.3rem;
    width: 1.6rem;
  }

  .alert.failed{
    background-color: $danger;

    & .icon{
      fill: $white;
    }
  }
</style>
