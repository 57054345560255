<template>
  <div id="modalWrap" />
  <div class="d-flex flex-column min-vh-100">
    <Header />

    <router-view v-slot="{ Component }">
      <template v-if="Component">
        <suspense>
          <component
            :is="Component"
            class="main flex-grow-1"
          />
        </suspense>
      </template>
    </router-view>

    <Footer />
  </div>
  <Loading />
  <Alert />
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Alert from '@/components/Alert.vue'
import Loading from '@/components/Loading.vue'
import { useStore } from 'vuex'
import { onMounted, provide, computed } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
// import { useFacebook } from '@/hooks/fb.js'

// const $ = require('jquery')
// window.$ = $

export default {
  components: {
    Header,
    Footer,
    Alert,
    Loading
  },
  setup () {
    // const { initFacebook } = useFacebook()
    const { commit, getters } = useStore()
    const { beforeEach } = useRouter()

    // pre load initFacebook
    // initFacebook()

    const memberID = computed(() => getters['member/getMemberID'])

    // function isMobile () {
    //   const flag = navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   )
    //   return flag
    // }

    const setAlert = (show, success, text) => {
      commit('setAlert', {
        data: {
          show,
          success,
          text
        }
      })

      if (show) {
        setTimeout(() => {
          commit('setAlert', {
            data: {
              show: false
            }
          })
        }, 1200)
      }
    }
    provide('setAlert', setAlert)

    onMounted(() => {
      const innerWidth = window.innerWidth
      commit('setIsMobile', { data: innerWidth < 768 })

      window.addEventListener('resize', () => {
        const innerWidth = window.innerWidth
        commit('setIsMobile', { data: innerWidth < 768 })
      })

      // if (isMobile()) {
      //   alert('移動端')
      //   // alert('PC端')
      //   // <![CDATA[
      //   /* 處理分享到 Line 的連結 */
      //   $('a').each(function () {
      //     var href = this.href

      //     // http 網址才處理
      //     if (href.indexOf('http') !== 0 || href.indexOf('//') < 0) {
      //       return
      //     }

      //     // 網址沒有參數 ? 時, 直接加參數
      //     if (href.indexOf('?') < 0) {
      //       href += '?openExternalBrowser=1'
      //     } else {
      //       // 網址有參數 ? 時, 用 & 加參數
      //       href += '&openExternalBrowser=1'
      //     }

      //     this.href = href
      //   })
      //   // ]]>
      // } else {
      // }
    })

    const scrollToTop = (from, callback) => {
      if (document?.documentElement) {
        document.documentElement.scroll({ top: 0, behavior: 'smooth' })
      }
    }
    // router beforeEach
    beforeEach((to, from, next) => {
      if (to.meta.requireAuth) {
        if (memberID.value) {
          next()
        } else {
          setAlert(true, false, '請先登入會員！')
          next('/login')
        }
      } else {
        next()
      }

      if (!(from.name === 'Home' && to.name === 'Home')) {
        scrollToTop(from)
      }
    })

    return {
      // isMobile
    }
  }
}
</script>
<style lang="scss" module></style>
