import { useContactAPI } from '../../hooks/api'

const state = () => ({

})

const mutations = {

}

const actions = {
  createContact: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/contacts'
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useContactAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
