<template>
  <div class="w-100">
    <div
      id="navbar-nav-shadow"
      :class="[{[$style[`active`]]: showNav}, $style[`navbarNavShadow`]]"
      @click="setShowNav"
    />
    <ul
      class="mx-auto navbar-nav px-4"
      :class="[{[$style[`active`]]: showNav}, {[$style[`rendered`]]: categoryList.length!==0}, $style[`navbarNav`]]"
    >
      <li
        v-for="(item,index) in activeMenuList"
        :key="index"
        class="nav-item my-4 fw-bold fs-5"
      >
        <a
          :href="item.link"
          class="link-secondary text-decoration-none"
        >{{ item.title }}</a>
      </li>
      <!-- divider -->
      <div class="dropdown-divider w-75 border border-primary my-4 d-block d-md-none" />

      <li
        v-if="memberID"
        class="d-flex d-md-none nav-item mb-0 fw-bold my-4"
      >
        <router-link
          class="nav-link"
          to="/member/detail"
        >
          會員中心
        </router-link>
      </li>
      <li
        v-if="memberID===null"
      >
        <router-link
          to="/login"
          class="btn btn-primary rounded-pill my-4"
        >
          登入
        </router-link>
      </li>
      <!-- <li
        v-if="memberID===null"
        class="d-flex d-md-none nav-item mb-0 fw-bold"
      >
        <a
          href="/login"
        >新用戶註冊</a>
      </li> -->
      <li
        v-if="memberID!==null"
      >
        <a
          class="btn btn-primary rounded-pill"
          :class="$style[`navLink`]"
          @click="logoutMember"
        >登出</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  props: {
    activeMenuList: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const { getters, commit } = useStore()
    const { push } = useRouter()

    const memberID = computed(() => getters['member/getMemberID'])
    const categoryList = computed(() => getters['product/getCategoryList'])

    const setAlert = inject('setAlert')
    const showNav = inject('showNav')
    const setShowNav = inject('setShowNav')
    // const setShowContactModal = inject('setShowContactModal')

    const queryString = ref('')

    const logoutMember = () => {
      commit('member/setMemberID', { data: null })
      commit('cart/setCartMount', { data: 0 })
      // localStorage.removeItem('memberID')
      setAlert(true, true, '您已成功登出')
      push('/')
    }

    return {
      logoutMember,
      queryString,
      memberID,
      showNav,
      setShowNav,
      // setShowContactModal,
      categoryList,
      push
    }
  }
}
</script>
<style lang="scss" module>
$navbar-height: 40px;

.icon {
  fill: currentColor;
  height: 0.9rem;
  width: 0.9rem;
}

/* navbar for mobile */
@media screen and (max-width: 768px) {
  .navbarNav {
    padding: 30% 0;
    background-color: $white;
    display: flex;
    height: 100vh;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.3s;
    width: 80%;
    z-index: 1200;
  }

  .navbarNav.active {
    transform: translateX(0%);
  }

  .navbarNavShadow {
    background-color: $dark;
    content: '';
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: -100vh;
    transition: opacity 0.3s;
    width: 100vw;
    z-index: 1199;
  }

  .navbarNavShadow.active {
    opacity: 0.5;
    top: 0;
  }
}

@media screen and (min-width: 768px) {
  .navbarNav {
    flex-wrap: wrap;
    justify-content: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s;
  }

  .navbarNav.rendered {
    background-color: $primary;
    max-height: 100vh;
    overflow: visible;
  }
}
</style>
