import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import member from '@/store/modules/member.js'
import product from '@/store/modules/product.js'
import validate from '@/store/modules/validate.js'
import cart from '@/store/modules/cart.js'
import contact from '@/store/modules/contact.js'
import order from '@/store/modules/order.js'
import payment from '@/store/modules/payment.js'

import * as Cookies from 'js-cookie'

export default createStore({
  state: {
    alert: {
      success: false,
      show: false,
      text: ''
    },
    isLoading: false,
    isMobile: false
  },
  mutations: {
    setAlert: (state, { data }) => {
      state.alert.show = data.show || false
      if (data?.success !== undefined) {
        state.alert.success = data.success
      }
      if (data?.text !== undefined) {
        state.alert.text = data.text
      }
    },
    setIsLoading: (state, { data }) => {
      state.isLoading = data
    },
    setIsMobile: (state, { data }) => {
      state.isMobile = data
    }
  },
  actions: {
  },
  getters: {
    getAlert: state => state.alert,
    getIsLoading: state => state.isLoading,
    getIsMobile: state => state.isMobile
  },
  modules: {
    member, contact, product, validate, cart, order, payment
  },
  plugins: [
    createPersistedState({
      key: '_State_',
      paths: ['member.memberID', 'order.orderID'],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 7, secure: true }),
        removeItem: (key) => Cookies.remove(key)
      }
    })
  ]
})
