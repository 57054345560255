import { useProductAPI } from '../../hooks/api'

const state = () => ({
  product: {},
  productList: [],
  hotProductList: [],
  categoryList: []
})

const mutations = {
  setProduct: (state, { data }) => {
    state.product = data
  },
  setProductList: (state, { data }) => {
    state.productList = data
  },
  setHotProductList: (state, { data }) => {
    state.hotProductList = data
  },
  setCategoryList: (state, { data }) => {
    state.categoryList = data
  }
}

const actions = {
  readProduct: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/products/' + payload.productID
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useProductAPI({ method, path, data })
      console.log(response)
      const responseData = response.data?.data || {}
      commit('setProduct', { data: responseData })
      return response
    } catch (error) {
      commit('setProduct', { data: {} })
      return Promise.reject(error)
    }
  },
  readProductList: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/products'
    const data = {
      params: { ...payload }
    }

    try {
      commit('setProductList', { data: [] })
      const response = await useProductAPI({ method, path, data })
      const responseData = response.data?.data?.productList || []
      commit('setProductList', { data: responseData })
      return response
    } catch (error) {
      commit('setProductList', { data: [] })
      return Promise.reject(error)
    }
  },
  readHotProductList: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/hot/products'
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useProductAPI({ method, path, data })
      const responseData = response.data?.data?.productList || []
      commit('setHotProductList', { data: responseData })
      return response
    } catch (error) {
      commit('setHotProductList', { data: [] })
      return Promise.reject(error)
    }
  },
  readCategoryList: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/category-combo'
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useProductAPI({ method, path, data })
      const responseData = response.data?.data?.categoryList || []
      commit('setCategoryList', { data: responseData })
      return response
    } catch (error) {
      commit('setCategoryList', { data: [] })
      return Promise.reject(error)
    }
  },
  readShipServiceInfo: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/specSelectCombo/' + payload.productID
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useProductAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  readPaywayInfo: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/web/payways'
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useProductAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const getters = {
  getProduct: state => state.product,
  getProductDetail: state => state.product?.product || {},
  getProductCopyWriter: state => state.product?.cw || [],
  getProductSpecDesc: state => state.product?.specDesc || [],
  getProductSpecList: state => state.product?.specPrice || [],
  getProductImageURLList: state => state.product?.img || [],
  getProductList: state => state.productList,
  getHotProductList: state => state.hotProductList,
  getCategoryList: state => state.categoryList
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
