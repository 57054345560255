import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/cart',
    name: 'CartTest',
    component: () => import('../views/CartTest.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/checkout',
    name: 'CheckoutTest',
    component: () => import('../views/CheckoutTest.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/order-confirm',
    name: 'OrderConfirm',
    component: () => import('../views/OrderConfirm.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/:clientID/reset/:token',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
    props: (route) => ({
      clientID: route.params.clientID,
      token: route.params.token
    })
  },
  {
    path: '/:clientID/activate/:memberID',
    name: 'AccountActivate',
    component: () => import('../views/AccountActivate.vue'),
    props: (route) => ({
      clientID: route.params.clientID,
      memberID: route.params.memberID
    })
  },
  {
    path: '/product/list',
    name: 'ProductList',
    component: () => import('../views/ProductList.vue'),
    props: (route) => ({
      queryString: route.query.queryString,
      categoryID: route.query.categoryID,
      catMainID: route.query.catMainID,
      catSubID: route.query.catSubID
    }),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/product/detail/:productID',
    name: 'ProductDetail',
    component: () => import('../views/ProductDetail.vue'),
    props: (route) => ({
      productID: route.params.productID
    }),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/Member.vue'),
    children: [
      {
        path: 'orders',
        name: 'MemberOrdersTest',
        component: () => import('../views/MemberOrdersTest.vue')
      },
      {
        path: 'detail',
        name: 'MemberDetailPro',
        component: () => import('../views/MemberDetailPro.vue')
      },
      {
        path: 'password',
        name: 'MemberPassword',
        component: () => import('../views/MemberPassword.vue')
      }
    ],
    meta: {
      requireAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
