import { useAPI } from '../../hooks/api'

const state = () => ({
  payways: [],
  paywaysBank: []
})

const mutations = {
  setPayways: (state, { data }) => {
    state.payways = data
  },
  setPaywaysBank: (state, { data }) => {
    state.paywaysBank = data
  }
}

const actions = {
  readPaywaysBank: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/payways/bankAccountInfo'
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useAPI({ method, path, data })
      const responseData = response.data?.data?.bankAccountInfo || []
      commit('setPaywaysBank', { data: responseData })
      return response
    } catch (error) {
      commit('setPaywaysBank', { data: [] })
      return Promise.reject(error)
    }
  },
  readPayways: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/payways/active'
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useAPI({ method, path, data })
      const responseData = response.data?.data || []
      commit('setPayways', { data: responseData })
      return response
    } catch (error) {
      commit('setPayways', { data: [] })
      return Promise.reject(error)
    }
  },
  updateNbpay: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/nbpay/paymentinfo/' + process.env.VUE_APP_clientID
    const data = {
      ...payload
    }

    try {
      const response = await useAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  createPaymentCustom: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/web/custompay/transfer'
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  createPaymentCard0: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/web/card0'
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  createPaymentNbpay: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/web/nbpay'
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  createPaymentEcpay: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/web/ecpay'
    // const data = new FormData()
    // for (const field in payload) {
    //   data.append(field, payload[field])
    // }
    const data = { params: { ...payload } }

    try {
      const response = await useAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const getters = {
  getPayways: state => state.payways,
  getPaywaysBank: state => state.paywaysBank
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
