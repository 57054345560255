<template>
  <header
    id="header"
    class="bg-white"
    :class="$style[`header`]"
  >
    <nav class="navbar navbar-expand-md p-0 container-fluid flex-column">
      <div
        class="col-12 px-3 d-flex flex-row align-items-center justify-content-between my-md-2 position-relative"
        style="min-height: 60px;"
      >
        <div class="d-flex align-items-center">
          <button
            class="navbar-toggler border-0 navbar-light rounded-0 text-primary"
            style="height: 60px;"
            type="button"
            @click="setShowNav"
          >
            <Icon
              name="menu"
              :class="$style[`navbarTogglerIcon`]"
            />
          </button>
          <!-- logo -->
          <router-link to="/">
            <img
              class="img-fluid"
              style="max-width: 110px;"
              src="@/assets/img/img-logo.png"
              alt="logo"
            >
          </router-link>
          <ul class="d-none d-md-flex list-unstyled mb-0 mx-5">
            <li
              v-for="(item,index) in activeMenuList"
              :key="index"
              class="fw-bold fs-5 link-secondary mx-4"
            >
              <a
                :href="item.link"
                class="link-secondary text-decoration-none py-4"
                :class="[[$style.link], {[$style.active]: item.link === fullPath}]"
              >{{ item.title }}</a>
            </li>
          </ul>
        </div>
        <!-- tools -->
        <div
          id="nav-tools"
          class="position-absolute end-0 d-flex align-items-center nav-tools"
        >
          <div>
            <div class="d-flex align-items-center">
              <router-link
                v-if="memberID"
                :to="'/member/detail'"
                class="d-flex link-secondary me-4"
                :class="[$style[`tool`], $style[`toolMember`]]"
              >
                <Icon
                  name="user"
                  :class="$style[`icon`]"
                />
              </router-link>
              <router-link
                v-else
                to="/login"
                class="d-flex link-secondary me-4"
                :class="[$style[`tool`], $style[`toolMember`]]"
              >
                <Icon
                  name="user"
                  :class="$style[`icon`]"
                />
              </router-link>
              <router-link
                to="/cart"
                class="link-secondary position-relative me-4"
                :class="[$style[`tool`], $style[`toolCart`]]"
                :data-amount="cartMount"
              >
                <Icon
                  name="cart"
                  :class="$style[`icon`]"
                />
              </router-link>
            </div>
          </div>

          <a
            v-if="memberID"
            role="button"
            class="d-none d-md-block link-secondary me-3 text-decoration-none"
            @click="logoutMember"
          >登出</a>
        </div>
      </div>

      <Navbar
        class="d-md-none"
        :active-menu-list="activeMenuList"
      />
    </nav>
  </header>
  <!-- 聯絡我們 -->
  <!-- <ContactModal ref="ContactModal" /> -->
</template>
<script>
import { computed, onMounted, ref, inject, provide, watch, toRef } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Navbar from '@/components/Navbar'
// import ContactModal from '@/components/ContactModal'

export default {
  components: {
    Navbar
    //  ContactModal
  },
  setup () {
    const { getters, dispatch, commit } = useStore()
    const { push } = useRouter()
    const route = useRoute()

    const fullPath = toRef(route, 'fullPath')

    const memberID = computed(() => getters['member/getMemberID'])
    const cartMount = computed(() => getters['cart/getCartMount'])
    const categoryList = computed(() => getters['product/getCategoryList'])

    const menuList = computed(() => [
      { title: '首頁', link: '/', if: computed(() => true) },
      { title: '關於我們', link: '/#aboutus', if: computed(() => true).value },
      { title: '公司沿革', link: '/#company', if: computed(() => true).value },
      { title: '商品', link: '/product/list', if: computed(() => memberID.value !== null && memberID.value !== '').value }
    ])
    const activeMenuList = computed(() => menuList.value.filter((item) => item.if))

    const setAlert = inject('setAlert')

    const queryString = ref('')
    const showNav = ref(false)
    // const ContactModal = ref(null)

    const setShowNav = (show) => {
      if (typeof show !== 'object') {
        showNav.value = show
      } else {
        showNav.value = !showNav.value
      }
    }

    watch(fullPath, () => {
      setShowNav(false)
    })

    const fieldList = ref(['name', 'email', 'contents'])

    // const setShowContactModal = (show) => {
    //   setShowNav(false)
    //   ContactModal.value.setShowContactModal(show)
    // }

    provide('showNav', showNav)
    provide('setShowNav', setShowNav)
    // provide('setShowContactModal', setShowContactModal)

    const getCategoryList = async () => {
      const payload = { }

      try {
        await dispatch('product/readCategoryList', { payload })
      } catch (error) {
        console.log(error)
      }
    }

    const logoutMember = () => {
      commit('member/setMemberID', { data: null })
      commit('cart/setCartMount', { data: 0 })
      // localStorage.removeItem('memberID')
      setAlert(true, true, '您已成功登出')
      push('/')
    }

    const readCartMount = async () => {
      if (memberID.value) {
        const payload = { memberID: memberID.value }

        try {
          const response = await dispatch('cart/readCartMount', { payload })
          if (response.data.code !== 200) {
          // setAlert(true, false, response.data.errMsg)
          }
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
        // setAlert(true, false, error)
        }
      } else {
        commit('cart/setCartMount', { data: 0 })
      }
    }

    const imageLoadError = (e) => {
      e.target.src = require('@/assets/img/nopic.gif')
    }

    if (categoryList.value.length === 0) {
      getCategoryList()
    }
    readCartMount()

    onMounted(() => {
      // var prevScrollpos = window.pageYOffset
      // window.addEventListener('scroll', () => {
      //   console.log(window.pageYOffset)
      // })
      // window.onscroll = function () {
      //   var currentScrollPos = window.pageYOffset
      //   if (prevScrollpos > currentScrollPos) {
      //     document.getElementByID('navbar-ul').style.top = '0'
      //   } else {
      //     document.getElementByID('navbar-ul').style.top = '-50px'
      //   }
      //   prevScrollpos = currentScrollPos
      // }
    })

    return {
      activeMenuList,
      queryString,
      // ContactModal,
      memberID,
      fieldList,
      showNav,
      setShowNav,
      getCategoryList,
      // setShowContactModal,
      cartMount,
      logoutMember,
      imageLoadError,
      categoryList,
      push,
      fullPath
    }
  }
}
</script>
<style lang="scss" module>

.link {
  position: relative;
  transition: color .3s;
}

.link::before {
  content: '';
  position: absolute;
  top: 0;
  height: 4px;
  width: 100%;
  background-color: $primary;
  transform: translateY(-10px);
  transition: transform .3s;
}

.link:hover,
.link.active {
  color: $primary;
}

.link.active::before {
  transform: translateY(-3px);
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1020;
}

.tool {
  align-items: center;
  background-color: $white;
  border-radius: 50%;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
}

.icon {
  fill: currentColor;
  height: 1.6rem;
  width: 1.6rem;
}

// member
.toolMember {
  color: $secondary;
  transition: color 0.3s;
}

// cart
.toolCart {
  color: $secondary;
  transition: background-color 0.3s;
}

.toolCart::after {
  background-color: $secondary;
  border-radius: 50%;
  color: $white;
  content: attr(data-amount);
  font-size: 0.3rem;
  height: 1rem;
  line-height: 1rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transform: translate(80%, 50%);
  transition: border-color 0.3s;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbarTogglerIcon {
  background-image: unset;
  fill: currentColor;
  height: 1.5rem;
  width: 1.5rem;
}

.shadow {
  background-color: rgba($dark, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(#{$zindex-modal} - 1);
}

.modal {
  max-width: 600px;
  width: 80vw;
  z-index: $zindex-modal;
}

</style>
