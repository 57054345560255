import { useMemberAPI, useValidateAPI } from '../../hooks/api'

const state = () => ({
  memberID: null,
  memberDetail: {},
  ordersAll: [],
  ordersCompleted: [],
  ordersInProcess: [],
  bonusAddedList: [],
  bonusUsedList: [],
  recipientRecord: {}
})

const mutations = {
  setMemberID: (state, { data }) => {
    state.memberID = data
  },
  setMemberDetail: (state, { data }) => {
    state.memberDetail = data
  },
  setOrdersAll: (state, { data }) => {
    state.ordersAll = data
  },
  setOrdersCompleted: (state, { data }) => {
    state.ordersCompleted = data
  },
  setOrdersInProcess: (state, { data }) => {
    state.ordersInProcess = data
  },
  setBonusAddedList: (state, { data }) => {
    state.bonusAddedList = data
  },
  setBonusUsedList: (state, { data }) => {
    state.bonusUsedList = data
  },
  setRecipientRecord: (state, { data }) => {
    state.recipientRecord = data
  }
}

const actions = {
  createHasSignInRequest: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/signIn/SSO'
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useValidateAPI({ method, path, data })
      const responseData = response.data?.data?.memberID || ''
      commit('setMemberID', { data: responseData })
      return response
    } catch (error) {
      commit('setMemberID', { data: '' })
      return Promise.reject(error)
    }
  },
  readMemberID: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/nokaptcha/signIn'
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useValidateAPI({ method, path, data })
      const responseData = response.data?.data?.memberID || ''
      commit('setMemberID', { data: responseData })
      return response
    } catch (error) {
      commit('setMemberID', { data: '' })
      return Promise.reject(error)
    }
  },
  createMemberID: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/signUp'
    const data = new FormData()
    for (const field in payload) {
      if (field.includes('birthday')) {
        const date = new Date(payload[field])
        data.append(field, `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`)
      } else {
        data.append(field, payload[field])
      }
    }

    try {
      const response = await useValidateAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  createMemberIDSSO: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/signUp/bindWithOrdinary/SSO'
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useValidateAPI({ method, path, data })
      const responseData = response.data?.data?.memberID || ''
      commit('setMemberID', { data: responseData })
      return response
    } catch (error) {
      commit('setMemberID', { data: '' })
      return Promise.reject(error)
    }
  },
  createFacebookIDSSO: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/account/sso/' + payload.memberID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useMemberAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  readMemberDetail: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/members/' + payload.memberID
    const data = { ...payload }

    try {
      const response = await useMemberAPI({ method, path, data })
      const responseData = response.data?.data || {}
      commit('setMemberDetail', { data: responseData })
      return response
    } catch (error) {
      commit('setMemberDetail', { data: {} })
      return Promise.reject(error)
    }
  },
  updateMemberDetail: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/members/' + payload.memberID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useMemberAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateMemberPassword: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/password/' + payload.memberID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useMemberAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  readOrdersAll: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/ordersForAll/' + payload.memberID
    const data = { params: { ...payload } }

    try {
      const response = await useMemberAPI({ method, path, data })
      const responseData = response.data?.data?.orderList || []
      commit('setOrdersAll', { data: responseData })
      return response
    } catch (error) {
      commit('setOrdersAll', { data: [] })
      return Promise.reject(error)
    }
  },
  readOrdersInProcess: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/ordersInProcess/' + payload.memberID
    const data = { params: { ...payload } }

    try {
      const response = await useMemberAPI({ method, path, data })
      const responseData = response.data?.data?.ordersInProcess || []
      commit('setOrdersInProcess', { data: responseData })
      return response
    } catch (error) {
      commit('setOrdersInProcess', { data: [] })
      return Promise.reject(error)
    }
  },
  readOrdersCompleted: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/ordersInHistory/' + payload.memberID
    const data = { params: { ...payload } }

    try {
      const response = await useMemberAPI({ method, path, data })
      const responseData = response.data?.data?.ordersInHistory || []
      commit('setOrdersCompleted', { data: responseData })
      return response
    } catch (error) {
      commit('setOrdersCompleted', { data: [] })
      return Promise.reject(error)
    }
  },
  readBonusAddedList: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/bonus/income/' + payload.memberID
    const data = { params: { ...payload } }

    try {
      const response = await useMemberAPI({ method, path, data })
      const responseData = response.data?.data?.bonusAdd || []
      commit('setBonusAddedList', { data: responseData })
      return response
    } catch (error) {
      commit('setBonusAddedList', { data: [] })
      return Promise.reject(error)
    }
  },
  readBonusUsedList: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/bonus/outcome/' + payload.memberID
    const data = { params: { ...payload } }

    try {
      const response = await useMemberAPI({ method, path, data })
      const responseData = response.data?.data?.bonusUse || []
      commit('setBonusUsedList', { data: responseData })
      return response
    } catch (error) {
      commit('setBonusUsedList', { data: [] })
      return Promise.reject(error)
    }
  },
  readRecipientRecord: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/rcpt/' + payload.memberID
    const data = { params: { ...payload } }

    try {
      const response = await useMemberAPI({ method, path, data })
      const responseData = response.data?.data || {}
      commit('setRecipientRecord', { data: responseData })
      return response
    } catch (error) {
      commit('setRecipientRecord', { data: {} })
      return Promise.reject(error)
    }
  },
  updateRecipientRecord: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/rcpt/' + payload.memberID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useMemberAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  deleteRecipientRecord: async ({ commit }, { payload }) => {
    const method = 'delete'
    const path = '/rcpt/' + payload.memberID
    const data = { params: { ...payload } }

    try {
      const response = await useMemberAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const getters = {
  getMemberID: (state) => state.memberID,
  getMemberDetail: (state) => state.memberDetail,
  getOrdersAll: (state) => state.ordersAll,
  getOrdersCompleted: (state) => state.ordersCompleted,
  getOrdersInProcess: (state) => state.ordersInProcess,
  getBonusAddedList: (state) => state.bonusAddedList,
  getBonusUsedList: (state) => state.bonusUsedList,
  getRecipientRecord: (state) => state.recipientRecord
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
